define("discourse/plugins/discourse-calendar/discourse/widgets/event-reminder-notification-item", ["discourse/lib/utilities", "discourse/widgets/default-notification-item", "discourse/widgets/widget", "discourse-common/lib/icon-library", "I18n"], function (_utilities, _defaultNotificationItem, _widget, _iconLibrary, _I18n) {
  "use strict";

  // TODO: delete the strings marked with TODO in translation files when
  // this file is removed

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "event-reminder-notification-item", {
    notificationTitle(notificationName, data) {
      return data.title ? _I18n.default.t(data.title) : "";
    },
    text(notificationName, data) {
      const username = (0, _utilities.formatUsername)(data.display_username);
      let description;
      if (data.topic_title) {
        description = `<span data-topic-id="${this.attrs.topic_id}">${(0, _utilities.escapeExpression)(data.topic_title)}</span>`;
      } else {
        description = this.description(data);
      }
      return _I18n.default.t(`${data.message}_html`, {
        description,
        username
      });
    },
    icon(notificationName, data) {
      return (0, _iconLibrary.iconNode)(`notification.${data.message}`);
    }
  });
});